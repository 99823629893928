import { ContentToolClient } from '@/agGridV2/helpers/mt-api.helper'

export default {
  state: {
    projects: [],
    selectedProject: null,
    client: null
  },
  getters: {
    getProjects(state) {
      return state.projects
    },
    getSelectedProject(state) {
      return state.selectedProject
    },
    getApiClient(state) {
      return state.client
    }
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects
    },
    setSelectedProject(state, project) {
      state.selectedProject = project
    },
    setApiClient(state, client) {
      state.client = client
    }
  },
  actions: {
    async authClient({ commit, state, getters }) {
      if (!getters.me && !state.client) {
        return false
      }
      const client = await ContentToolClient.create({
        id: getters.me.id,
        name: getters.me.full_name
      })
      commit('setApiClient', client)
    },
    async loadProjects({ commit, state }) {
      try {
        if (!state.client) {
          return false
        }
        const projects = await state.client.projects.get()
        const projectsFiltered = [
          {
            _id: 'all',
            name: 'All'
          }
        ]
        for (const { _id, abbr, type, platform } of projects) {
          projectsFiltered.push({
            _id,
            name: `${abbr}-${type} (${platform ? platform.url : 'not found'})`
          })
        }
        commit('setProjects', projectsFiltered)
        if (state.selectedProject) {
          const updatedSelectedProject = projectsFiltered.find(
            (project) => project._id === state.selectedProject._id
          )
          if (updatedSelectedProject) {
            commit('setSelectedProject', updatedSelectedProject)
          } else {
            commit('setSelectedProject', projectsFiltered[0])
          }
        } else {
          commit('setSelectedProject', projectsFiltered[0])
        }
      } catch (error) {
        console.error('Error while getting projects:', error)
      }
    }
  }
}
